import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog/dialog.component';
import { SharedUiModule } from '@mca/shared/ui';
import { SharedFeatureDynamicFormModule } from '@mca/shared/feature-dynamic-form';
import { FileDropDirective, FileSelectDirective } from '@mca/shared/feature-file-uploader';
import { FileImporterComponent } from './file-importer/file-importer.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  imports: [
    CommonModule,
    SharedUiModule,
    SharedFeatureDynamicFormModule,
    DropdownModule,
    FormsModule,
    DragDropModule,
    DialogModule,
    FileDropDirective,
    FileSelectDirective,
    DialogComponent,
    FileImporterComponent,
  ],
  exports: [DialogComponent],
})
export class SharedFeatureDialogModule {}
