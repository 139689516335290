<div class="dialog-header">
  <h6 class="dialog-title">File Uploader</h6>
  <div (click)="close()"><i class="fa fa-close"></i></div>
</div>
<div class="card" #modal>
  <div class="card-body">
    <div>
      <div class="col-12">
        <input type="file" libSharedFileUploaderSelect [uploader]="uploader" multiple /><br />

        <div
          libSharedFileUploaderDrop
          [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
          (fileOver)="fileOverBase($event)"
          [uploader]="uploader"
          class="well my-drop-zone"
        >
          Drop zone
        </div>
      </div>
    </div>

    <div>
      <table class="table">
        <thead>
          <tr>
            <th width="50%">Name</th>
            <th>Size</th>
            <th>Progress</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>

        <tbody>
          @for (item of uploader.queue; track item) {
            <tr>
              <td>
                <strong>{{ item?.file?.name }}</strong>
              </td>
              @if (uploader.options.isHTML5) {
                <td nowrap>{{ item?.file?.size / 1024 / 1024 | number: '.2' }} MB</td>
              }
              @if (uploader.options.isHTML5) {
                <td>
                  <div class="progress" style="margin-bottom: 0">
                    <div class="progress-bar" role="progressbar" [ngStyle]="{ width: item.progress + '%' }"></div>
                  </div>
                </td>
              }
              <td class="text-center">
                @if (item.isSuccess) {
                  <span><i class="glyphicon glyphicon-ok"></i></span>
                }
                @if (item.isCancel) {
                  <span><i class="glyphicon glyphicon-ban-circle"></i></span>
                }
                @if (item.isError) {
                  <span><i class="glyphicon glyphicon-remove"></i></span>
                }
              </td>
              <td nowrap>
                <button
                  type="button"
                  class="btn btn-success btn-xs"
                  (click)="item.upload()"
                  [disabled]="!category || item.isReady || item.isUploading || item.isSuccess"
                >
                  <span class="glyphicon glyphicon-upload"></span> Upload
                </button>
                <button type="button" class="btn btn-warning btn-xs" (click)="item.cancel()" [disabled]="!item.isUploading">
                  <span class="glyphicon glyphicon-ban-circle"></span> Cancel
                </button>
                <button type="button" class="btn btn-danger btn-xs" (click)="item.remove()">
                  <span class="glyphicon glyphicon-trash"></span> Remove
                </button>
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    <div>
      <div>
        Queue progress:
        <div class="progress">
          <div class="progress-bar" role="progressbar" [ngStyle]="{ width: uploader.progress + '%' }"></div>
        </div>
      </div>

      <div class="footer flex-column mt-1" #modalFooter>
        @if (!category && uploader.getNotUploadedItems().length) {
          <p class="text-danger">
            <small> Please select classification to upload document(s) </small>
          </p>
        }
        <div class="row align-items-center">
          <div class="ui-float-label col-3">
            @if (classificationList()) {
              <p-dropdown
                [options]="classificationList()"
                [ngModel]="null"
                (onChange)="changeCategory($event.value)"
                [filter]="true"
                [virtualScroll]="false"
                [virtualScrollItemSize]="24.5"
                [overlayOptions]="{ autoZIndex: true, baseZIndex: 1100, appendTo: 'body' }"
                [showClear]="true"
                [required]="true"
              ></p-dropdown>
            }
            <label class="active">Classification</label>
          </div>
          <button
            type="button"
            class="btn btn-success btn-s col-auto"
            (click)="uploader.uploadAll()"
            [disabled]="!category || !uploader.getNotUploadedItems().length"
          >
            <span class="glyphicon glyphicon-upload"></span> Upload all
          </button>

          <button type="button" class="btn btn-warning btn-s col-auto" (click)="uploader.cancelAll()" [disabled]="!uploader.isUploading">
            <span class="glyphicon glyphicon-ban-circle"></span> Cancel all
          </button>

          <button type="button" class="btn btn-danger btn-s col-auto" (click)="uploader.clearQueue()" [disabled]="!uploader.queue.length">
            <span class="glyphicon glyphicon-trash"></span> Remove all
          </button>

          <button type="button" class="btn btn-light btn-s col-auto" (click)="close()">
            <span class="glyphicon glyphicon-upload"></span> Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
