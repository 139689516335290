import { Component, model } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FieldConfig } from '../models/field-config.interface';
import { Field } from '../models/field.interface';

@Component({
  selector: 'lib-shared-dynamic-form-divider-form',
  template: `
    <div class="my-2">
      @if (config().label) {
        <h5>{{ config().label }}</h5>
      }
      <div class="divider dropdown-divider"></div>
    </div>
  `,
  standalone: true,
})
export class DividerFormComponent implements Field {
  config = model({} as FieldConfig);
  group = model({} as FormGroup);
}
