import { Component, model } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldConfig, OptionsSelectConfig } from '../models/field-config.interface';
import { Field } from '../models/field.interface';
import { FormErrorsComponent } from '@mca/shared/ui';
import { ToggleButtonModule } from 'primeng/togglebutton';

@Component({
  selector: 'lib-shared-dynamic-form-toggle',
  template: `
    <div class="dynamic-field form-input form-group" [formGroup]="group()">
      <p-toggleButton [onLabel]="config().label ?? ''" [offLabel]="config().label ?? ''" [formControl]="control"></p-toggleButton>
      <lib-shared-ui-form-errors [control]="control" [label]="config().label ?? ''"></lib-shared-ui-form-errors>
    </div>
  `,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ToggleButtonModule, FormErrorsComponent],
})
export class FormToggleComponent implements Field {
  config = model({} as FieldConfig);
  group = model({} as FormGroup);

  options = {} as OptionsSelectConfig;

  get control() {
    return this.group().controls[this.config().name] as FormControl;
  }

  actionToggle(): void {
    this.control.setValue(!this.control.value);
  }
}
