import {
  ComponentRef,
  DestroyRef,
  Directive,
  EventEmitter,
  OnChanges,
  OnInit,
  Renderer2,
  ViewContainerRef,
  inject,
  model,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fieldComponents, FieldConfig } from '../models/field-config.interface';
import { Field } from '../models/field.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
  selector: '[libSharedDynamicFormField]',
  standalone: true,
})
export class DynamicFieldDirective implements Field, OnChanges, OnInit {
  private container = inject(ViewContainerRef);
  private renderer2 = inject(Renderer2);
  private destroyRef = inject(DestroyRef);

  config = model({} as FieldConfig);
  group = model({} as FormGroup);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: ComponentRef<Field & any>;

  ngOnChanges() {
    if (this.component) {
      this.component.instance.config.set(this.config());
      this.component.instance.group.set(this.group());
    }
  }

  ngOnInit(): void {
    if (this.config().type === 'template') {
      this.createTemplate();
      return;
    }
    if (this.config().type === 'component') {
      this.createComponent();
      return;
    }
    this.createField();
  }

  private createTemplate() {
    const template = this.config().template;
    if (template) {
      this.container.createEmbeddedView(template, { form: this.group(), config: this.config() }, { injector: this.config().injector });
    }
  }

  private createComponent() {
    const component = this.config().component;
    if (!component) {
      return;
    }
    this.component = this.container.createComponent(component, { injector: this.config().injector });
    Object.assign(this.component.instance, {
      dynamicFormControl: this.group().controls[this.config().name],
      ...(this.config().componentInputs ?? {}),
    });
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
    for (const [key, outputEmitter] of Object.entries(this.config().componentOutputs ?? {})) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.component.instance[key] as EventEmitter<any>)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(value => outputEmitter(value, this.group(), this.config()));
    }
  }

  private createField() {
    const componentType = fieldComponents[this.config().type ?? 'input'];
    if (!componentType) {
      return;
    }
    this.component = this.container.createComponent<Field>(componentType, { injector: this.config().injector });
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
    Object.assign(this.component.instance, this.config().componentInputs ?? {});
    const classes = (this.config().class ?? 'col-12').split(' ');
    classes.forEach(className => this.renderer2.addClass(this.component?.location.nativeElement, className));
  }
}
