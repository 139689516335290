import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { NgxMaskDirective } from 'ngx-mask';
import { SharedUiModule } from '@mca/shared/ui';
import { DynamicFieldDirective } from './dynamic-field/dynamic-field.directive';
import { DynamicFormComponent } from './dynamic-form.component';
import { FormButtonComponent } from './form-button/form-button.component';
import { FormInputComponent } from './form-input/form-input.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { FormMultiselectComponent } from './form-multiselect/form-multiselect.component';
import { FormCheckboxComponent } from './form-checkbox/form-checkbox.component';
import { FormTextareaComponent } from './form-textarea/form-textarea.component';
import { FormToggleComponent } from './form-toggle/form-toggle.component';
import { FormDatepickerComponent } from './form-datepicker/form-datepicker.component';
import { DividerFormComponent } from './divider-form/divider-form.component';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { FormMessageComponent } from './form-message/form-message.component';
import { TooltipModule } from 'primeng/tooltip';
import { FormMultiInputComponent } from './form-multi-input/form-multi-input.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CalendarModule,
    DropdownModule,
    MultiSelectModule,
    ToggleButtonModule,
    SharedUiModule,
    TooltipModule,
    OverlayPanelModule,
    NgxMaskDirective,
    DynamicFieldDirective,
    DynamicFormComponent,
    FormButtonComponent,
    FormInputComponent,
    FormSelectComponent,
    FormMultiselectComponent,
    FormCheckboxComponent,
    FormTextareaComponent,
    FormToggleComponent,
    FormDatepickerComponent,
    FormMessageComponent,
    DividerFormComponent,
    FormMultiInputComponent,
  ],
  exports: [DynamicFormComponent],
})
export class SharedFeatureDynamicFormModule {}
