import { Component, OnInit, input, signal, inject } from '@angular/core';
import { Subject } from 'rxjs';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthService } from '@mca/auth/api';
import { DialogService } from '../dialog/dialog.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FileItem, FileUploader, FileUploaderOptions } from '@mca/shared/feature-file-uploader';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { NgClass, NgStyle, DecimalPipe } from '@angular/common';
import { FileDropDirective } from '@mca/shared/feature-file-uploader';
import { FileSelectDirective } from '@mca/shared/feature-file-uploader';

@Component({
  selector: 'lib-shared-dialog-file-importer',
  templateUrl: './file-importer.component.html',
  styleUrls: ['./file-importer.component.scss'],
  standalone: true,
  imports: [FileSelectDirective, FileDropDirective, NgClass, NgStyle, DropdownModule, FormsModule, DecimalPipe],
})
export class FileImporterComponent implements OnInit {
  private dialogService = inject(DialogService);
  private authService = inject(AuthService);
  private dialogConfig = inject(DynamicDialogConfig, { optional: true });

  uploadUrl = input(this.dialogConfig?.data.uploadUrl ?? '');
  classificationList = input(this.dialogConfig?.data.classificationList ?? []);
  loading = signal(false);
  out$ = new Subject<boolean>();
  // used to display closing confirmation message
  closing$ = new Subject<boolean>();

  uploader = new FileUploader({ url: this.uploadUrl() });
  hasBaseDropZoneOver = false;
  category = 0;

  ngOnInit() {
    this.uploader = new FileUploader({ url: this.uploadUrl() });
    this.uploader.onBeforeUploadItem = (item: FileItem) => {
      this.updateHeaders();
      const categoryParam = this.category ? `?category=${this.category}` : '';
      item.url = this.uploadUrl() + categoryParam;
    };
  }

  fileOverBase(e: boolean) {
    this.hasBaseDropZoneOver = e;
  }

  close() {
    this.out$.next(!!this.uploader.queue.length);
    this.dialogService.closeModal();
  }

  changeCategory(category: number | null) {
    this.category = category ?? 0;
  }

  private updateHeaders() {
    const token = this.authService.token;
    const headers = [{ name: 'Authorization', value: 'Bearer ' + token }];
    const uplOpt = { headers } as FileUploaderOptions;
    this.uploader.setOptions(uplOpt);
  }
}
