<p-overlayPanel #op [style]="{ width: 'content-max', minWidth: '24rem', padding: '0' }">
  <ng-template pTemplate="content">
    <ul class="m-0 p-0">
      @for (item of control.value; track trackBy(i); let i = $index) {
        <li class="d-flex align-items-center justify-content-between mt-3">
          <div class="position-relative w-100">
            <span class="form-control opacity-0" aria-hidden="true">{{ control.value[i] }}&nbsp;</span>
            <input
              type="text"
              class="form-control position-absolute top-0 left-0"
              [readonly]="config().readonly"
              [(ngModel)]="control.value[i]"
            />
          </div>
          <button class="btn btn-danger btn-sm w-auto ms-3" type="button" (click)="$event.stopPropagation(); removeOption(i)">
            <i class="fa fa-trash"></i>
          </button>
        </li>
      }
    </ul>
    <div class="row mx-1 mt-3 align-items-center">
      <div class="col-6">
        @if (!config().readonly) {
          <button class="btn btn-secondary w-100" type="button" (click)="addOption()">Add</button>
        }
      </div>
      <div class="col-6">
        <button class="btn btn-primary w-100" type="button" (click)="op.hide()" autofocus>OK</button>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>
<div class="dynamic-field form-input w-100" [class.ui-float-label]="config().labelType === 'float'">
  <label class="active"
    >{{ config().label }}
    @if (config().required) {
      <span>*</span>
    }
  </label>
  <div class="d-flex w-100 align-items-center justify-content-between">
    <p class="form-control text-truncate w-100" (click)="!config().disabled && op.toggle($event)">
      @for (item of control.value; track item; let first = $first; let last = $last) {
        <span [ngClass]="{ 'border-start': !first, 'me-2': !last, 'ps-2': !first }">{{ item }}</span>
      }
      <span>&nbsp;</span>
    </p>
  </div>
</div>
<lib-shared-ui-form-errors [control]="control" [label]="config().label ?? ''"></lib-shared-ui-form-errors>
