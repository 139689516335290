<div class="dynamic-field" [formGroup]="group()">
  <div class="w-100" [ngClass]="{ 'ui-float-label': config().labelType === 'float' }">
    <label class="active"
      >{{ config().label }}
      @if (config().required) {
        <span>*</span>
      }
    </label>
    @if (options && config().options?.items) {
      <p-dropdown
        [formControl]="control"
        [options]="config().options?.items"
        [required]="config().required ?? false"
        [placeholder]="config().placeholder!"
        [optionLabel]="options.bindLabel!"
        [optionValue]="options.bindValue!"
        [virtualScroll]="false"
        [virtualScrollItemSize]="24.5"
        [overlayOptions]="{ autoZIndex: true, baseZIndex: 1100, appendTo: 'body' }"
        [showClear]="config().options?.clearable ?? false"
        [autoDisplayFirst]="false"
        [filter]="true"
        class="w-100"
      >
        <ng-template let-item pTemplate="selectedItem">
          <span class="d-block" [pTooltip]="item.tooltip">{{ item[options.bindLabel!] }}</span>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <span class="d-block" [pTooltip]="item.tooltip">{{ item[options.bindLabel!] }}</span>
        </ng-template>
      </p-dropdown>
    } @else {
      @if (config().options?.items.length) {
        <select class="form-control">
          <option>
            <div>Loading ...</div>
          </option>
        </select>
      } @else {
        <select class="form-control" disabled>
          <option>
            <div>No available {{ config().label }}s</div>
          </option>
        </select>
      }
    }
  </div>

  <lib-shared-ui-form-errors [control]="control" [label]="config().label ?? ''"></lib-shared-ui-form-errors>
</div>
