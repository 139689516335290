import { Component, model } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldConfig } from '../models/field-config.interface';
import { Field } from '../models/field.interface';

@Component({
  selector: 'lib-shared-dynamic-form-button',
  styleUrls: ['form-button.component.scss'],
  template: `
    <div class="dynamic-field form-button" [formGroup]="group()">
      <button [disabled]="config().disabled" type="submit">
        {{ config().label }}
      </button>
    </div>
  `,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
})
export class FormButtonComponent implements Field {
  config = model({} as FieldConfig);
  group = model({} as FormGroup);
}
