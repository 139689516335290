import { FieldConfig } from './field-config.interface';

export const updateDynamicFormFields = (fields: FieldConfig[], values: Partial<FieldConfig>[]) => {
  for (const v of values) {
    const field = fields.find(f => f.name === v.name);
    if (field) {
      Object.assign(field, v);
    }
  }
};
