import { Component, model } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldConfig } from '../models/field-config.interface';
import { Field } from '../models/field.interface';
import { FormErrorsComponent } from '@mca/shared/ui';
import { NgClass } from '@angular/common';

@Component({
  selector: 'lib-shared-dynamic-form-textarea',
  template: `
    <div class="dynamic-field form-input form-group" [ngClass]="{ 'ui-float-label': config().labelType === 'float' }" [formGroup]="group()">
      <label [for]="config().name">
        {{ config().label }}
        @if (config().required) {
          <span>*</span>
        }
      </label>

      <textarea
        class="form-control"
        [formControlName]="config().name"
        [required]="config().required ?? false"
        [id]="config().name"
      ></textarea>
      <lib-shared-ui-form-errors [control]="control" [label]="config().label ?? ''"></lib-shared-ui-form-errors>
    </div>
  `,
  standalone: true,
  imports: [NgClass, FormsModule, ReactiveFormsModule, FormErrorsComponent],
})
export class FormTextareaComponent implements Field {
  config = model({} as FieldConfig);
  group = model({} as FormGroup);

  get control() {
    return this.group().controls[this.config().name];
  }
}
