import { Component, OnInit, model } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldConfig, OptionsSelectConfig } from '../models/field-config.interface';
import { Field } from '../models/field.interface';
import { FormErrorsComponent } from '@mca/shared/ui';
import { TooltipModule } from 'primeng/tooltip';
import { SharedModule } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { NgClass } from '@angular/common';

@Component({
  selector: 'lib-shared-dynamic-form-select',
  templateUrl: 'form-select.component.html',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, DropdownModule, SharedModule, TooltipModule, FormErrorsComponent],
})
export class FormSelectComponent implements Field, OnInit {
  config = model({} as FieldConfig);
  group = model({} as FormGroup);

  options = {} as OptionsSelectConfig;

  get control() {
    return this.group().controls[this.config().name] as FormControl;
  }

  ngOnInit(): void {
    this.options = {
      items: null,
      multiple: false,
      bindLabel: 'value',
      bindValue: 'id',
      ...this.config().options,
    };
  }
}
