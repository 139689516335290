// @see https://github.com/valor-software/ng2-file-upload/blob/development/libs/ng2-file-upload/file-upload/file-select.directive.ts
import { Directive, ElementRef, HostListener, inject, input, output } from '@angular/core';
import { FileUploader, FileUploaderOptions } from './file-uploader';

@Directive({ selector: '[libSharedFileUploaderSelect]', standalone: true })
export class FileSelectDirective {
  protected element = inject(ElementRef);

  uploader = input<FileUploader>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  onFileSelected = output<File[]>();

  getOptions(): FileUploaderOptions | undefined {
    return this.uploader()?.options;
  }

  getFilters(): string {
    return '';
  }

  isEmptyAfterSelection(): boolean {
    return !!this.element.nativeElement.attributes.multiple;
  }

  @HostListener('change')
  onChange(): void {
    const files = this.element.nativeElement.files;
    const options = this.getOptions();
    const filters = this.getFilters();
    this.uploader()?.addToQueue(files, options, filters);

    this.onFileSelected.emit(files);
    if (this.isEmptyAfterSelection()) {
      this.element.nativeElement.value = '';
    }
  }
}
