import { Component, Input, model } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { dateAsYYYYMMDD } from '@mca/shared/util';
import { FieldConfig } from '../models/field-config.interface';
import { Field } from '../models/field.interface';
import { CalendarTypeView, CalendarModule } from 'primeng/calendar';
import { FormErrorsComponent } from '@mca/shared/ui';
import { NgClass } from '@angular/common';

@Component({
  selector: 'lib-shared-dynamic-form-datepicker',
  template: `
    <div class="dynamic-field form-input form-group">
      <div [ngClass]="{ 'ui-float-label': config().labelType === 'float' }">
        <label class="active"
          >{{ config().label }}
          @if (config().required) {
            <span>*</span>
          }
        </label>
        <p-calendar
          [ngModel]="control.value"
          (ngModelChange)="valueChanged($event)"
          [dateFormat]="dateFormat"
          [view]="view"
          [showTime]="false"
          [minDate]="config().minDate!"
          [maxDate]="config().maxDate!"
          [required]="config().required ?? false"
          [showButtonBar]="true"
          [disabled]="!!config().disabled"
          appendTo="body"
        >
        </p-calendar>
      </div>

      <lib-shared-ui-form-errors [control]="control" [label]="config().label ?? ''"></lib-shared-ui-form-errors>
    </div>
  `,
  standalone: true,
  imports: [NgClass, CalendarModule, FormsModule, FormErrorsComponent],
})
export class FormDatepickerComponent implements Field {
  // keep as Input for compatibility with dynamic form componentInputs
  @Input() dateFormat = 'yy-mm-dd';
  @Input() view = 'date' as CalendarTypeView;

  config = model({} as FieldConfig);
  group = model({} as FormGroup);

  get control() {
    return this.group().controls[this.config().name];
  }

  valueChanged(v: Date) {
    if (this.dateFormat !== 'yy-mm-dd') {
      this.control.setValue(v);
      return;
    }
    const value = v && dateAsYYYYMMDD(v);
    if (value !== this.control.value) {
      this.control.setValue(value);
    }
  }
}
