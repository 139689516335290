import { Component, model } from '@angular/core';
import { FieldConfig } from '../models/field-config.interface';
import { Field } from '../models/field.interface';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-shared-dynamic-form-message',
  template: `<p>{{ config().value }}</p>`,
  standalone: true,
})
export class FormMessageComponent implements Field {
  config = model({} as FieldConfig);
  group = model({} as FormGroup);
}
