import { Component, model } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldConfig, OptionsSelectConfig } from '../models/field-config.interface';
import { Field } from '../models/field.interface';
import { FormErrorsComponent } from '@mca/shared/ui';

@Component({
  selector: 'lib-shared-dynamic-form-checkbox',
  template: `
    <div class="dynamic-field form-input form-group d-flex flex-wrap" [formGroup]="group()">
      <div class="d-flex flex-nowrap align-items-center">
        <input
          class="form-check-inline position-relative m-0"
          type="checkbox"
          [formControlName]="config().name"
          [required]="config().required ?? false"
          [id]="config().name"
          [checked]="config().value"
          role="button"
        />
        <label class="form-check-label ms-2" [for]="config().name" role="button">
          {{ config().label }}
          @if (config().labelHelper) {
            <span class="text-black-50">{{ config().labelHelper }}</span>
          }
          @if (config().required) {
            <span>*</span>
          }
        </label>
      </div>
      <lib-shared-ui-form-errors [control]="control" [label]="config().label ?? ''"></lib-shared-ui-form-errors>
    </div>
  `,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FormErrorsComponent],
})
export class FormCheckboxComponent implements Field {
  config = model({} as FieldConfig);
  group = model({} as FormGroup);

  options = {} as OptionsSelectConfig;

  get control() {
    return this.group().controls[this.config().name];
  }
}
